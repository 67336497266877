import {Dispatch, SetStateAction, useCallback, useState} from 'react'

type TPlainFunc = () => void
export function useToggleState(initialState = false, [on, off] = [true, false]) {
	const [isState, setIsState] = useState(initialState)

	const toggleState = useCallback<() => void>(() => {
		setIsState((s) => (s === on ? off : on))
	}, [on, off])

	return [isState, toggleState, setIsState] as [
		boolean,
		TPlainFunc,
		Dispatch<SetStateAction<boolean>>,
	]
}
