import React, {FC, useState} from 'react'

import cn from 'classnames/bind'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import Link from 'next/link'

import Button from 'components/elements/Button'
import paystubDefaultValues from 'src/components/blocks/documents/DocumentPaystub/templates/config/templates.defaultValues'
import {posthogTrack, proxyDefaultWrapper} from 'src/utils'
import {usePayStubStore} from 'src/utils/contexts/RootStore.context'
import {useToggleState} from 'src/utils/hooks/useToggleState'

import s from './DocumentToolsItem.module.scss'

import Icon1099Src from '/public/icons/tools-item/f1099.png'
import IconPaystubSrc from '/public/icons/tools-item/paystub.png'
import IconW2Src from '/public/icons/tools-item/w2.png'

const PopupFormPreview = dynamic(() => import('components/blocks/PopupFormPreview'))

const Form1099Template1 = dynamic(
	() => import('components/blocks/documents/DocumentForm1099/templates/Form1099Template1'),
)
const PaystubTemplate1 = dynamic(
	() => import('components/blocks/documents/DocumentPaystub/templates/PaystubTemplate1'),
)
const W2Template1 = dynamic(
	() => import('components/blocks/documents/DocumentW2/templates/W2Template1'),
)

const cx = cn.bind(s)
const Popup = dynamic(() => import('components/blocks/Popup'), {ssr: false})

export const getDocumentToolsConfig = () => ({
	paystub: {
		title: 'Paystub',
		href: '/make-your-paystub',
		formPreview: (data: docs.IPaystubData | undefined) => <PaystubTemplate1 data={data} />,
		iconSrc: IconPaystubSrc,
	},
	w2: {
		title: 'W-2 Form',
		href: '/make-your-w2',
		formPreview: <W2Template1 data={undefined} />,
		iconSrc: IconW2Src,
	},
	form1099: {
		title: '1099 Misc.',
		href: '/make-your-1099',
		formPreview: <Form1099Template1 data={undefined} />,
		iconSrc: Icon1099Src,
	},
})

interface IDocumentToolsItemProps {
	className?: string
	variant: keyof ReturnType<typeof getDocumentToolsConfig>
	title?: string | JSX.Element
	withPreview?: boolean
	btnText?: string
	onClick?: () => void
	disabledButton?: boolean
	isLoading?: boolean
	href?: string
}

const DocumentToolsItem: FC<IDocumentToolsItemProps> = ({
	variant,
	title = '',
	withPreview = true,
	btnText = 'Build now!',
	href,
	onClick,
	disabledButton = false,
	isLoading,
	className,
}) => {
	const data = getDocumentToolsConfig()[variant]
	const [isOpenDocPreview, openToggleDocPreview] = useToggleState(false)
	const [didOpenedDocPreview, setDidOpenedDocPreview] = useState(false)

	const paystubStore = usePayStubStore()
	const paystubStoreWithDefaults = proxyDefaultWrapper(paystubStore, paystubDefaultValues)

	return (
		<div className={cx('wrapper', className, variant)}>
			<div className={cx('content', 'global-content')}>
				<div className={cx('title', 'h2', 'text-center')}>{title ? title : data.title}</div>

				<div className={cx('card')}>
					{onClick ? (
						<figure className={cx('icon__wrapper')}>
							<Image priority src={data.iconSrc} alt={`${data.title} icon`} />
						</figure>
					) : (
						<Link
							prefetch={false}
							passHref
							href={href ? href : data.href}
							aria-label={btnText}
							className={cx('card__icon', 'card__icon--link')}>
							<figure className={cx('icon__wrapper')}>
								<Image priority src={data.iconSrc} alt={`${data.title} icon`} />
							</figure>
						</Link>
					)}
					{withPreview ? (
						<Button
							className={cx('card__preview-btn')}
							variant="secondary"
							size="small"
							onClick={() => {
								openToggleDocPreview()
								setDidOpenedDocPreview(true)
								posthogTrack('[CARD ITEM] Preview button to open modal clicked', {
									document: variant,
									pageHref: location.pathname,
								})
							}}
							data-testid="btn-doc-preview">
							See Example
						</Button>
					) : null}
				</div>
				{!onClick && (
					<Link prefetch={false} passHref href={href ? href : data.href} legacyBehavior>
						<Button
							className={cx('btn-build')}
							as="a"
							size="big"
							aria-label={btnText}
							data-testid="btn-doc-build"
							onClick={() => {
								posthogTrack('[CARD ITEM] Build Now! button clicked', {
									document: variant,
									pageHref: location.pathname,
								})
							}}>
							{btnText}
						</Button>
					</Link>
				)}
				{onClick && (
					<Button
						className={cx('btn-build')}
						as="a"
						size="big"
						onClick={() => {
							onClick()
							posthogTrack('[CARD ITEM] Build Now! button clicked', {
								document: variant,
								pageHref: location.pathname,
							})
						}}
						isLoading={isLoading}
						disabled={disabledButton}
						data-testid="btn-doc-build">
						{btnText}
					</Button>
				)}
			</div>
			<div className={cx('bottom')}></div>
			{withPreview && didOpenedDocPreview ? (
				<Popup isOpen={isOpenDocPreview} onClose={openToggleDocPreview}>
					<PopupFormPreview
						formPreview={
							typeof data.formPreview === 'function'
								? data.formPreview(paystubStoreWithDefaults)
								: data.formPreview
						}
						onClose={openToggleDocPreview}
						buildHref={data.href}
					/>
				</Popup>
			) : null}
		</div>
	)
}

DocumentToolsItem.displayName = 'DocumentToolsItem'

export default DocumentToolsItem
