import React, {FC} from 'react'

import cn from 'classnames/bind'
import Image from 'next/legacy/image'

import s from './ImagePlaceholder.module.scss'
const cx = cn.bind(s)

interface IImagePlaceholderProps {
	className?: string
	width?: number
	height?: number
	/** default it's post*/
	text?: string
}

const ImagePlaceholder: FC<IImagePlaceholderProps> = ({
	className,
	width = 538,
	height = 352,
	text = 'post',
}) => {
	return (
		<Image
			className={cx('wrapper', className)}
			src={`http://placehold.jp/3d4070/ffffff/${width}x${height}.png?text=${text}`}
			width={width}
			height={height}
			layout="fill"
			objectFit="cover"
			alt="Placeholder"
		/>
	)
}

ImagePlaceholder.displayName = 'ImagePlaceholder'

export default ImagePlaceholder
