import React, {FC, useMemo} from 'react'

import Head from 'next/head'

interface ISeoProps {
	description: string
	title: string
	siteTitle?: string
	keywords?: string | string[]
	svgImgPath?: string
}

const SEO: FC<ISeoProps> = ({description, title, siteTitle, keywords, svgImgPath}) => {
	const preparedKeywords = useMemo(() => {
		if (typeof keywords === 'string') return keywords
		if (Array.isArray(keywords)) {
			return keywords.join(',')
		}
	}, [keywords])

	return (
		<Head>
			<title>{`${title} ${siteTitle ? `| ${siteTitle}` : ''}`}</title>
			<meta name="description" content={description} />
			{preparedKeywords && <meta name="keywords" content={preparedKeywords} />}

			<meta property="og:type" content="website" />
			{svgImgPath && <meta property="og:image" content={svgImgPath} />}
			<meta property="og:image:type" content="image/svg+xml" />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
			<meta property="og:site_name" content={siteTitle} />
		</Head>
	)
}

SEO.displayName = 'SEO'

export default SEO
