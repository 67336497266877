import React, {FC} from 'react'

import cn from 'classnames/bind'
import Image from 'next/image'
import Link from 'next/link'

import ImagePlaceholder from 'components/elements/ImagePlaceholder'
import {TPost} from 'src/types/blog'
import {getFormattedDate} from 'src/utils'

import s from './BlogItem.module.scss'
const cx = cn.bind(s)

interface IBlogItemProps {
	className?: string
	post: TPost
	isVertical?: boolean
	index: number
}

const BlogItem: FC<IBlogItemProps> = ({className, post, isVertical, index}) => {
	const hasImage =
		(post?.mainImage && typeof post?.mainImage === 'string') ||
		(typeof post?.mainImage === 'object' && post?.mainImage?.url)
	const src = typeof post?.mainImage === 'object' ? post?.mainImage?.url : post?.mainImage
	const alt = (typeof post?.mainImage === 'object' ? post?.mainImage?.alt : post.title) || ''

	return (
		<section className={cx('wrapper', className, {isVertical})}>
			<figure className={cx('figure')}>
				<Link passHref href={`/posts/${post.slug?.current}`} className={cx('img-link')}>
					{hasImage ? (
						<Image
							className={cx('img')}
							src={src as string}
							alt={alt}
							fill
							sizes="(max-width: 767px) 100vw, 15vw"
							priority={index < 3}
						/>
					) : (
						<ImagePlaceholder />
					)}
				</Link>
			</figure>

			<div className={cx('content')}>
				<h3 className={cx('title', 'h3')}>
					<Link passHref href={`/posts/${post.slug?.current}`} legacyBehavior>
						{post.title}
					</Link>
				</h3>
				<p className={cx('description')}>{post.description}</p>
				<footer className={cx('footer')}>
					{post.publishedAt && (
						<time className={cx('date')}>{getFormattedDate(new Date(post.publishedAt))}</time>
					)}
					<Link href={`/posts/${post.slug?.current}`} className={cx('read', 'fs-xxs')}>
						Read
					</Link>
				</footer>
			</div>
		</section>
	)
}

BlogItem.displayName = 'BlogItem'

export default BlogItem
