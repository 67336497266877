import {FC} from 'react'

import cn from 'classnames/bind'

import {Col, Container, Row} from 'components/elements/grid'

import s from './DocumentToolsList.module.scss'
import DocumentToolsItem, {getDocumentToolsConfig} from '../DocumentToolsItem/DocumentToolsItem'
const cx = cn.bind(s)

interface IDocumentToolsListProps {
	className?: string
	withPreview?: boolean
}

const DocumentToolsList: FC<IDocumentToolsListProps> = ({className, withPreview}) => {
	const documentToolsConfig = getDocumentToolsConfig()
	return (
		<Container className={cx('wrapper', className)}>
			<Row as="ul" className={cx('list')}>
				{Object.keys(documentToolsConfig).map((key) => (
					<Col as="li" key={key} md={4} className={cx('list__item')}>
						<DocumentToolsItem
							variant={key as keyof typeof documentToolsConfig}
							withPreview={withPreview}
						/>
					</Col>
				))}
			</Row>
		</Container>
	)
}

DocumentToolsList.displayName = 'DocumentToolsList'

export default DocumentToolsList
