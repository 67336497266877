const defaultValues = {
	coNumber: 'gny',
	fileNumber: 154632,
	deptNumber: 527062,
	clockNumber: 45087,
	vchrNumber: '0000026709',
	checkNo: 3307,
	oftenPaid: 'Weekly',
	paymentType: 'Hourly',
	companyInfo: {
		employerName: 'Company name',
		employerTelephone: '',
		streetAddress1: 'Company street address 1',
		streetAddress2: 'Company street address 2',
		city: 'Company city',
		state: 'al',
		zipCode: '1025',
		payrollLogo: '',
		employerLogo: '',
	},
	employeeInfo: {
		employeeName: 'Employee name',
		employeeTelephone: '',
		employeeSocial: '',
		employeeId: '008',
		streetAddress1: 'Employee street address 1',
		streetAddress2: 'Employee street address 2',
		city: 'Employee city',
		state: 'al',
		zipCode: '1025',
		maritalStatus: 'Single',
		dependentsAmount: '0',
		isDirectDeposit: null,
		last4Digits: '',
	},
	items: [
		{
			earnings: {
				regular: {
					id: 1,
					name: 'Regular',
					key: 'regular',
					rate: '10.00',
					hours: '40.00',
					total: '400.00',
					ytd: '1,600.00',
				},
				// add more earnings if needed
			},
			deductions: {
				medicare: {
					key: 'medicare',
					name: 'FICA Medicare',
					total: '5.80',
					ytd: '17.40',
				},
				fica: {
					key: 'fica',
					name: 'FICA Social Security',
					total: '24.80',
					ytd: '74.40',
				},
				federal: {
					key: 'federal',
					name: 'Federal Tax',
					total: '0.00',
					ytd: '0.00',
				},
				state: {
					key: 'state',
					name: 'State Tax',
					total: '6.83',
					ytd: '20.49',
				},
				// add more deductions if needed
			},
			grossPayTotal: '400.00',
			grossPayYTDTotal: '1,600.00',
			deductionTotal: '37.43',
			deductionYTDTotal: '149.73',
			netpayTotal: '362.57',
			netpayYTDTotal: '1,450.27',
			payPeriod: {from: '02/18/2022', to: '05/28/2022'},
			payDate: '05/28/2022',
			preTaxDeduction: 0,
			pastTaxDeduction: 0,
			netCheck: '0.00',
		},
	],

	other: {
		currency: '$',
		importantNotes:
			'The Meal and Rest Break Policy provides you with the opportunity to take meal and rest breaks. As provided in the Meal and Rest Break Policy, requirements vary by state. If you believe that you have been denied the opportunity to take the meal and rest breaks during your scheduled shift under the Meal and Rest Break Policy, promptly notify payroll. ****************** Please review the hours worked and pay in this paycheck and earnings statement, if you believe that there are any errors, promptly notify Payroll.',
	},
}

export default defaultValues
